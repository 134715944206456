import React from 'react'
import allFonts from './allFonts'
import config from '../lib/config'
const getFonts = (fonts) => {
  return fonts.map((font, index) => (
    <link
      key={index}
      rel="prefetch"
      href={
        config.IS_PROD
          ? `${config.CDN_API_URL}fonts/${font.name}.${font.type}`
          : `/static/assets/fonts/${font.name}.${font.type}`
      }
      as="font"
      crossOrigin="anonymous"
    />
  ))
}

const getFontFaces = (fonts) => {
  return fonts
    .map(
      (font) => `@font-face {
    font-family: ${font.fontFamily ? font.fontFamily : font.name};
    font-display: swap;
    src: url('${
      config.IS_PROD
        ? `${config.CDN_API_URL}fonts/${font.name}.${font.type}`
        : `/static/assets/fonts/${font.name}.${font.type}`
    }') format('${font.type}');
  }`,
    )
    .join('\n')
}

// Create component with all <link> for all fonts
const FontLinks = () => getFonts(allFonts)

// Create all font faces in one single string
const getFontFacesStr = getFontFaces(allFonts)

export { FontLinks, getFontFacesStr }
