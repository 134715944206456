import { rest } from 'msw'

import display from '../cypress/fixtures/campaign/display.json'
import displayLanding from '../cypress/fixtures/campaign/displayLanding.json'
import displayCampaign from '../cypress/fixtures/campaign/displayCampaign.json'

export const handlers = [
  rest.get(
    `http://campaign-service.green.room/api/v1/campaign/utmCheck/display`,
    (req, res, ctx) => {
      return res(ctx.json(displayCampaign))
    },
  ),

  rest.get(
    `http://campaign-service.green.room/api/v1/reader/page/${displayCampaign._id}/landing/display`,
    (req, res, ctx) => {
      return res(ctx.json(displayLanding))
    },
  ),
  rest.get(
    `http://campaign-service.green.room/api/v1/reader/campaign/${display.campaign}/display`,
    (req, res, ctx) => {
      return res(ctx.json(display))
    },
  ),
  rest.get(
    `http://campaign-service.green.room/api/v1/reader/campaign/${display.campaign}/landing/display`,
    (req, res, ctx) => {
      return res(ctx.json(displayLanding))
    },
  ),
  rest.get(
    `http://campaign-service.green.room/api/v1/reader/campaign/adidas/display`,
    (req, res, ctx) => {
      return res(ctx.json({ isClosed: true, slug: 'adidas-non-stop' }))
    },
  ),
  rest.get(
    `http://campaign-service.green.room/api/v1/reader/campaign/a-fail-campaign/display`,
    (req, res, ctx) => {
      return res(ctx.status(404))
    },
  ),
  rest.get(
    `http://campaign-service.green.room/api/v1/reader/campaign/${displayCampaign._id}/theming`,
    (req, res, ctx) => {
      return res(
        ctx.json({
          styles: {
            root: {
              fontFamily: 'regular',
              fontSize: '16px',
              backgroundColor: 'white',
              color: 'blue',
              a: {
                textDecoration: 'none',
                color: 'blue',
              },
            },
          },
          input: {
            background: 'black',
          },
        }),
      )
    },
  ),

  rest.post(
    `/api/campaign/e578c8c5-7547-48d3-a869-5ab8157952f5/submit`,
    (req, res, ctx) => {
      return res(
        ctx.json({ data: 'hola', email: 'a@a.com', session_id: '2351' }),
      )
    },
  ),

  rest.get('*', (req, res, ctx) => {
    console.log('*******', req.url)
    return res(ctx.json({}))
  }),
]
